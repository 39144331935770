import React, { useState } from "react";
import "./navbar.css";
import SearchBar from "../search-component/Searchbar";
import Knowdis from "../../Assets/Knowdis.png";
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useUserContext } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
const Navbar = ({
  handleEnter,
  handleGptClick,
  ensemble,
  indiamart,
  handleIndiamartClick,
}) => {
  const { Logout , user} = useUserContext();
  const navigate = useNavigate();
  const handleButtonClick = async () => {
    try {
  
      await Logout()
      navigate("/signin")
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <nav className="navbar">
      <div className="logo-container">
        <img src={Knowdis} alt="Logo" className="logo" />
      </div>

      <h2>ZSearch V2</h2>

      <div className="center-container">
        <SearchBar handleEnter={handleEnter} />
      </div>

      <div className="button">
        <Button
        onClick={handleButtonClick}
          sx={{
            fontWeight: 800,
            color: 'white'
          }} variant="contained">Logout <LogoutIcon /> </Button>
        {/* <button
          className={`GptClass ${indiamart ? "enabled" : ""} ensemble-button`}
          onClick={handleIndiamartClick}
        >
          IndiaMART {indiamart && <span>&#10004;</span>}
        </button>
        */}
	  {/* <button
          className={`${ensemble ? "enabled" : ""} ensemble-button`}
          onClick={handleGptClick}
        >
          Boosted {ensemble && <span>&#10004;</span>}
        </button>*/} 
      </div>
    </nav>
  );
};

export default Navbar;
